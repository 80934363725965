const appConfig = {
    // apiPrefix: 'http://localhost:4000/api/',
    apiPrefix: 'https://travelbackend-nvys.onrender.com',  

    // apiPrefix: 'http://localhost:8000',
    authenticatedEntryPath: {
        sanchalak: '/dashboardSanchlak',
        chetriye_sanchalak: '/dashboard'
    },
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
